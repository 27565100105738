<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Clé de label par activité" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une clé de label par activité</div>
              </v-row>
            </v-card-title>

            <!-- la clé -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">la clé</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="La clé"
                    dense
                    v-model="key"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le label par défaut -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    label par défaut
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label par défaut"
                    dense
                    v-model="defaultLabel"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>            

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { ConfAppAvecIndicatorsService } from "@/service/conf/app_avec_indicators_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddConfAvecKeysLabelsActivities",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /**La donnée originale */
      source: null,
      /**la key */
      key: null,
      /** le label par défaut */
      defaultLabel: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      let success = true;     

      // enregistrement de l'activité
    
      try {
        let entity = {};
        entity.key = this.key;
        entity.defaultLabel = this.defaultLabel;
        entity.labelsByActivity = [];

        await this.service.create(entity);
      } catch (error) {
        success = false;
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement du label : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }

      if (success) {
        this.$router.go(-1);
      }
    },
  },
  computed: {

    canSave() {
      try {
        if (!this.key || this.key == "") {
          return false;
        }

        if (!this.defaultLabel || this.defaultLabel == "") {
          return false;
        }

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new ConfAppAvecIndicatorsService(this.$api.getAppAvecIndicatorsApi());
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>